//css-------------------
import '../../css/share/common.scss';
import '../../css/home/welcome.scss';

//swiper
import( /* webpackPrefetch: true */ 'swiper/css');
import( /* webpackPrefetch: true */ 'swiper/css/pagination');
import( /* webpackPrefetch: true */ 'swiper/css/navigation');

//fontawesome
import( /* webpackPrefetch: true */ '../../lib/fontawesome/fontawesome.scss');
import( /* webpackPrefetch: true */ '../../lib/fontawesome/regular.scss');
import( /* webpackPrefetch: true */ '../../lib/fontawesome/brands.scss');

//js----------------------
//components
import( /* webpackPrefetch: true */ '../share/components/_header');
import( /* webpackPrefetch: true */ '../share/components/_cookie_bar');
import( /* webpackPrefetch: true */ '../share/components/_font_family');
import( /* webpackPrefetch: true */ '../share/components/_welcome_lazy');
import( /* webpackPrefetch: true */ '../share/components/_welcome_lazy');
import( /* webpackPrefetch: true */ '../share/components/_ytplayer');

//npm lib
import $ from "jquery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import imagesLoaded from "imagesloaded";
import Swiper from 'swiper';
import { Pagination, Navigation, A11y } from 'swiper/modules';
import { createApp } from 'vue/dist/vue.esm-browser.prod.js';

$(function() {

    const app = createApp({
        data() {
            return {
                current_date: null,
                current_month: null,
                current_time: null,
            }
        },
        mounted() {
            const _this = this;

            this.get_current_date();
            this.get_current_month();
            setInterval(this.get_current_time, 1000);

            imagesLoaded("html, body", function() {

                $(".loading").fadeOut(function() {

                    _this.hash_to();
                    _this.ind_swiper();
                    _this.ind_ani();

                    $(this).remove();

                    var _url = location.href;
                    _url = _url.split('?');
                    $('.kv_video').html('<video class="video" src="/video/kv.mp4" playsinline autoplay preload loop muted></video>');

                });
            });
        },
        methods: {
            get_current_date() {
                this.current_date = new Date().getDate();
            },
            get_current_month() {
                this.current_month = new Date().getMonth() + 1;
            },
            get_current_time() {
                this.current_time = new Date().toLocaleTimeString("en-GB");
            },
            ind_swiper() {
                const $swiper = '.chronology_swiper';
                const pagination = $swiper + ' .swiper-pagination';
                const button_next = $swiper + ' .swiper-button-next';
                const button_prev = $swiper + ' .swiper-button-prev';

                const mySwiper = new Swiper($swiper, {
                    modules: [Pagination, Navigation, A11y],
                    slidesPerView: "auto",
                    grabCursor: true,
                    freeMode: {
                        momentumVelocityRatio: 0.5,
                    },
                    pagination: {
                        el: pagination,
                        clickable: true,
                    },
                    navigation: {
                        nextEl: button_next,
                        prevEl: button_prev,
                    },
                    a11y: {
                        enabled: true,
                        firstSlideMessage: "第一則",
                        lastSlideMessage: "最後一則",
                        paginationBulletMessage: "到第 {{index}} 則",
                    },
                });
            },
            ind_ani() {
                // kv

                gsap.registerPlugin(ScrollTrigger);

                gsap.timeline()
                    .to('.kv_txt_bottom', { duration: .8, delay: .8, opacity: 1, x: 0, ease: 'circ.out' })
                    .to('.kv_txt_left', { duration: .8, opacity: 1, x: 0, ease: 'circ.out' }, '-=.4')



                // outline
                var scene = document.getElementById('outline_scene');
                // var parallaxInstance = new Parallax(scene);
                var _px = 0;

                function myFunction() {
                    if (_px > 100) gsap.ticker.remove(myFunction);
                    _px += 1.2;
                    gsap.set('.outline_round', { clipPath: 'circle(' + _px + '% at 50% 75%)' })
                }

                gsap.timeline({
                        scrollTrigger: {
                            trigger: '#trigger_outline',
                            start: "top 70%",
                            toggleActions: "play pause reverse reset",
                        }
                    })
                    .to('.kv_title', { duration: .8, opacity: 0, ease: 'power0.out' })

                gsap.timeline({
                        scrollTrigger: {
                            trigger: '#trigger_outline',
                            start: "top center",
                        }
                    })
                    .from('.outline_title_1, .outline_title_2', { duration: .8, delay: .5, opacity: 0, y: -80, ease: 'quart.out' })
                    .from('.outline_title_round', { duration: .8, opacity: 0, ease: 'circ.out' }, '-=.5')
                    .from('.outline_row', { duration: .8, stagger: .15, opacity: 0, x: 50, ease: 'circ.out' }, '-=.5')


                gsap.timeline({
                    scrollTrigger: {
                        trigger: '#trigger_outline',
                        start: "top 40%",
                        onEnter: () => {
                            gsap.ticker.add(myFunction);
                        },
                    }
                })


                // chronology
                gsap.timeline({
                        scrollTrigger: {
                            trigger: '#trigger_chrono',
                            start: "top center",
                        }
                    })
                    .from('.chrono_circle', { duration: .7, opacity: 0, scale: .6, ease: 'power0.out' })
                    .to('.chrono_line', { duration: 2, width: '100%', ease: 'quart.out' }, '-=.1')
                    .from('.swiper-slide', { duration: .8, stagger: .15, opacity: 0, ease: 'power1.out' }, '-=1.5')
                    .from('.chronology_title_img', { duration: .8, opacity: 0, x: -30, ease: 'circ.out' }, '-=1.8')


                // news
                gsap.timeline({
                        scrollTrigger: {
                            trigger: '#trigger_news',
                        }
                    })
                    .from('.news_mainTitle', { duration: .8, opacity: 0, y: -50, ease: 'quart.out' })
                    .from('.news_item> *', { duration: .8, stagger: .15, opacity: 0, x: 60, ease: 'circ.out' }, '-=.6')



                // symposium
                gsap.timeline({
                        scrollTrigger: {
                            trigger: '#trigger_symposium',
                        }
                    })
                    .from('.symposium_mainTitle', { duration: 1, delay: .5, opacity: 0, y: -70, ease: 'circ.out' })
                    .from('.symposium_round', { duration: 1, opacity: 0, x: -70, ease: 'circ.out' }, '-=.8')
                    .from('.symposium_caption', { duration: 1, stagger: .16, opacity: 0, y: 140, ease: 'quart.out' }, '-=.7')



                // news spot ------------------------------------------

                const news_sec = $(".news_sec");
                const news_mask = $(".news_mask");
                const news_offsetTop = $(".news_sec").offset().top;
                let _x, _y;

                news_sec.mousemove(function(event) {
                    _x = event.pageX / 16;
                    _y = (event.pageY - news_offsetTop) / 16;
                    news_mask.addClass('active');
                    news_mask.css('clip-path', 'circle(20.3125rem at ' + _x + 'rem ' + _y + 'rem)');
                });
                news_sec.mouseleave(function(event) {
                    news_mask.removeClass('active');
                });
            },
            hash_to() {
                var hash = location.hash.substr(1);
                if (hash) {
                    hash = hash.replace('anchor_', '');
                    var _scrollTop = $('#' + hash).offset().top - $(window).height() / 3;
                    gsap.to('html, body', { duration: 1.5, scrollTop: _scrollTop, ease: 'quart.out' });
                }
            },
            lb_chronology() {
                gsap.to('#lb_chronology', { duration: .4, opacity: 1, display: 'flex', ease: 'circ.out' });
            },
            lb_close(e) {
                if ($(e.target).is('.lightbox') || $(e.target).is('.btnClose_link')) {
                    gsap.to('.lightbox', { duration: .3, opacity: 0, display: 'none', ease: 'circ.out' });
                }
            },
        },
    }).mount('#app');


});